import React from "react";
import "./FullPageSpinner.css"; 

const FullPageSpinner = () => {
  return (
    <div className="full-page-spinner">
      <div className="spinner"></div>
    </div>
  );
};

export default FullPageSpinner;
