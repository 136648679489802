import { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";

const AuthContext = createContext();
const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    token: "",
  });

  // Set default axios headers
  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = auth.token;
  }, [auth.token]);

  useEffect(() => {
    const data = localStorage.getItem("auth");
    if (data) {
      try {
        const parseData = JSON.parse(data);
        setAuth({
          ...auth,
          user: parseData?.savedUser,
          token: parseData?.token,
        });
        if (parseData?.savedUser?.verified === false) {
          checkVerificationStatus(parseData.token);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, []); // Run only on component mount

  // Function to check verification status
  const checkVerificationStatus = async (token) => {
    const data = localStorage.getItem("auth");
    try {
      const response = await axios.get(
        `https://www.api.collocean.com/api/v1/auth/check-verification-status`,
        {
          headers: { Authorization: `${token}` }, // Fix the Authorization header format
        }
      );
      const { verified } = response.data;
      if (verified) {
        // Update user data in local storage and state if verified
        const parseData = JSON.parse(data);
        parseData.savedUser.verified = true;
        localStorage.setItem("auth", JSON.stringify(parseData));
        setAuth({
          ...auth,
          verified: true,
        });
      }
    } catch (error) {
      console.error("Error checking verification status:", error);
    }
  };

  const logout = () => {
    setAuth({ user: null, token: "" });
    localStorage.removeItem("auth");
  };

  return (
    <AuthContext.Provider value={[auth, setAuth, logout]}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access authentication context
const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
